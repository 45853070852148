export const main = {
  title: 'Dashboard',
  // callbackName: '__refreshCustomersWithPagination',
  // refresh: 'customers',
  children: [
    {
      title: 'Dashboard',
      route: 'main-dash',
      icon: 'mdi-ballot-outline'
    }
  ]
}
