export const concierge = {
  conciergeName: {
    title: 'Concierge name',
    type: 'simple-text',
    value: ''
  },
  conciergePhoneWork: {
    title: 'Concierge phone (work)',
    type: 'phone',
    value: ''
  },
  phoneWork: {
    title: 'Phone (work)',
    type: 'phone',
    value: ''
  },
  phoneMobile: {
    title: 'Phone (mobile)',
    type: 'mobile',
    value: ''
  },
  emailPrimary: {
    title: 'Email (primary)',
    type: 'email',
    value: ''
  },
  emailAlternative: {
    title: 'Email (alternative)',
    type: 'email',
    value: ''
  }
}
