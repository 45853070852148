export const debuggerConsole = [
  {
    key: 'broken-link',
    title: 'Broken links'
  },
  {
    key: 'save-imported-customers',
    title: 'Sending imported customers to server'
  },
  {
    key: 'import-customer-updates',
    title: 'Customer was not found in DB'
  }
]
