import {
  corporation,
  management,
  marketing,
  owner,
  concierge
} from './buildingSchemaSections'

export const buildingSchema = {
  corporation,
  management,
  marketing,
  owner,
  concierge
}
