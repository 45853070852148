export const newCustomer = {
  uniqueCode: '',
  status: 'Awaiting for connection',
  postCode: '',
  apartmentNumber: '',
  address: '',
  commercial: {},
  firstName: '',
  lastName: '',
  phoneMobile: '',
  phoneWork: '',
  primaryEmail: '',
  alternativeEmail: '',
  buildingId: '',
  resellerId: '',
  serviceId: '',
  serviceStatus: '',
  approxETA: ''
}
