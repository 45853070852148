import Vue from 'vue'

import { getExtensionForVue } from './getExtensionForVue'
import { setGlobals } from './setGlobals'

import { createAdminWorker } from './controllers'

import configPlugin from '../config'

Vue.use(configPlugin)

Object.assign(Vue.prototype, getExtensionForVue())

window[Symbol.for('vue.prototype')] = Vue.prototype

window[Symbol.for('api.host')] = Vue.prototype.$apiHost()

Object.assign(String.prototype, {
  upperCaseCharIndex: function () {
    return this.split('').findIndex(char => char.charCodeAt(0) > 32 && char.charCodeAt(0) < 91)
  },
  transformToTitle: function () {
    let result = this
      .slice(1)
      .split('-').join(' ')
      .split('_').join(' ')

    while (true) {
      const index = result.upperCaseCharIndex()
      if (index === -1) break
      const char = result.slice(index, index + 1)
      result = result.replace(char, ` ${char.toLowerCase()}`)
    }

    return this[0].toUpperCase() + result
  }
})

Object.assign(Storage.prototype, {
  smartClear: function () {
    const [unload, beforeUnload] = [this.getItem('unload'), this.getItem('before-unload')]
    this.clear()
    this.setItem('unload', unload)
    this.setItem('before-unload', beforeUnload)
  }
})

setGlobals()

createAdminWorker()

export default Vue
