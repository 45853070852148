<template>
  <v-card class="transparent" v-if="opened" transition="fade">
    <div class="debugger-console-title pa-2">
      <v-btn icon @click="resetErrors" class="mr-5">
        <v-icon color="#fffd"> mdi-window-minimize </v-icon>
      </v-btn>
      <small>{{ new Date().toISOString().slice(0, 10) }}</small>
    </div>

    <div class="debugger-console-content" id="debugger-console-content">
      <v-expansion-panels flat class="transparent" :value="keys.length - 1">
        <v-expansion-panel v-for="(key, index) of keys" :key="index">
          <v-expansion-panel-header>
            <span>
              <v-icon small color="#09b" v-if="getInfo(index)"> mdi-bell-check-outline </v-icon>
              <v-icon small color="#a00" v-if="getError(index)"> mdi-alert </v-icon>
              <v-icon small color="#a00" v-if="getWarn(index)"> mdi-bell-badge-outline </v-icon>
              <b :color="types[index] === 'error' ? '#a00' : types[index] === 'info' ? '#09b' : '#fa0'">{{ titles[index] }}</b>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <p v-for="(message, num) of messages[key]" :key="`${key}.${num}`" class="pl-5">
              {{ message }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>

const { debuggerConsole } = require('@/configs').default

export default {
  name: 'DebugConsole',

  data: () => ({
    console: null,
    opened: false,
    lastKey: '',
    lastTitle: '',
    keys: [],
    titles: [],
    messages: {},
    warnList: [],
    groupTitle: ''
  }),

  methods: {
    resetErrors () {
      this.opened = false
      this.errorList = []
      this.lastTitle = ''
    },

    clearLog (key) {
      localStorage.removeItem(key)
    },

    clearAll () {
      localStorage.smartClear()
    },

    getError (index) {
      return this.types[index] === 'error'
    },

    getInfo (index) {
      return this.types[index] === 'info'
    },

    getWarn (index) {
      return this.types[index] !== 'error' && this.types[index] !== 'info'
    },

    getGroupTitle () {
      // return `<small>Debugger</small> <b>${new Date().toISOString().slice(0, 10)}</b> <small>log</small> <b>${new Date().toLocaleTimeString()}</b>`
    },

    selectKey () {
      this.groupTitle = this.getGroupTitle()
      this.warnList = debuggerConsole.map(item => item.title)
    },

    showLastRecords () {
      if (!this.lastKey) return
      const item = localStorage.getItem(this.lastKey)
      if (!item) return
      const { title, records } = JSON.parse(item)
      this.groupTitle = title || this.getGroupTitle()
      this.warnList = records
      this.opened = true
    },

    showRecordsByKey (key) {
      if (!key) return
      this.lastKey = key
      this.showLastRecords()
    },

    open (data) {
      if (!data) this.init()

      this.opened = true
    },

    getTime () {
      const date = new Date()
      const [hours, minutes] = [
        date.getHours().toString().padStart(2, '0'),
        (date.getMinutes() + (date.getSeconds() > 40)).toString().padStart(2, '0')
      ]
      return `${hours}:${minutes}`
    },

    getKeys () {
      const tmp = localStorage.getItem('debugger-console-keys')
      return tmp ? JSON.parse(tmp).filter(key => localStorage.getItem(key)) : []
    },

    updateKeys (keys) {
      localStorage.setItem('debugger-console-keys', JSON.stringify(keys))
    },

    addKey (key) {
      const keys = this.getKeys()
      if (!keys.includes(key)) {
        keys.push(key)
        this.updateKeys(keys)
      }
      return keys
    },

    init () {
      this.keys = this.getKeys()
      const records = this.keys.map(key => JSON.parse(localStorage.getItem(key)))

      this.titles = records.map(record => record.title)
      this.types = records.map(record => record.type)

      this.messages = this.keys.reduce((res, key, index) => Object.assign(res, { [key]: records[index].records }), {})
    },

    updateRecords (data) {
      const { key, message, title, type } = data

      const lastKey = `${this.getTime()} ${key}`
      const lastTitle = `${this.getTime()} ${title}`

      this.keys = this.addKey(lastKey)

      // this.titles.includes(lastTitle) || this.titles.push(lastTitle)

      if (!this.titles.includes(lastTitle)) {
        this.titles.push(lastTitle)
        this.types.push(type)
      }

      this.messages[lastKey]
        ? this.messages[lastKey].push(message)
        : Object.assign(this.messages, { [lastKey]: [message] })

      const item = localStorage.getItem(lastKey)
      const records = item ? JSON.parse(item).records : []
      records.push(message)
      localStorage.setItem(lastKey, JSON.stringify({ title: lastTitle, records }))
      this.opened = true

      this.$nextTick(() => {
        const console = document.getElementById('debugger-console-content')
        console.scroll({ top: console.scrollHeight, behavior: 'smooth' })
      })
    }
  },

  beforeDestroy () {
    this.$root.$off('open-debugger-console', this.open)
    this.$root.$off('update-debugger-console-data', this.updateRecords)
    this.$root.$off('close-debugger-console', this.resetErrors)
    this.$root.$off('clear-debugger-console', this.clearLog)
  },

  mounted () {
    localStorage.smartClear()

    this.init()

    this.$root.$on('open-debugger-console', this.open)
    this.$root.$on('update-debugger-console-data', this.updateRecords)
    this.$root.$on('close-debugger-console', this.resetErrors)
    this.$root.$on('clear-debugger-console', this.clearLog)
  }
}
</script>

<style>
.debugger-console-title {
  position: fixed;
  z-index: 49;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  left: 0;
  bottom: 448px;
  width: 40%;
  height: 48px;
  background: #555;
  color: #eee;
  box-shadow: 4px -4px 8px #0005 !important;
}

.debugger-console-record {
  /* color: #900; */
  font-weight: bold;
}

.debugger-console-content {
  position: fixed;
  z-index: 50;
  left: 0;
  bottom: 28px;
  width: 40%;
  min-height: 420px;
  max-height: 420px;
  padding: 16px;
  background: #fbfbfb;
  box-shadow: 4px 0 8px #0005 !important;
  overflow-y: auto;
}

.debugger-console-content p {
  font-size: 12px;
  color: #555;
}

.debugger-console-content h5 {
  font-size: 14px;
  font-weight: bold;
  color: #900;
}
</style>
