import { eventsTable } from '@/controllers/events-table'
import { dictionary } from '@/controllers/events'

const [route, action] = ['dictionary', 'update-check-address-messages']

export const updateCheckAddressMessages = function (data, callback) {
  window[Symbol.for('vue.prototype')].$sendMessageToWorker({ route, action, data })

  eventsTable[dictionary[action]] = callback
}
