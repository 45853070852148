export const serviceIconColors = {
  Active: '#09b',
  'Awaiting for connection': 'primary',
  'Awaiting for confirmation': 'primary',
  'Awaiting to be suspended': 'primary',
  'Awaiting to be resumed': 'primary',
  'Awaiting for cancelation': 'primary',
  'Awaiting confirmation': 'primary',
  'Awaiting for scheduling': '#888',
  'In job queue': '#888',
  'Unable to connect': '#555',
  'Not connected': '#f00',
  Suspended: '#888',
  Canceled: '#888'
}
